import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersPage = () => (
  <Layout>
    <SEO title="Partners" />
  </Layout>
)

export default PartnersPage
